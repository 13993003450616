<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="dialogAddRawMaterialToPurchaseOrder"
    :width="screenWidth + '%'"
    scrollable
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-add-raw-material-purchase-order">
          <p class="title-add-raw-material-purchase-order">
            Añadir materia prima <br />
            a la orden de compra
          </p>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :disable-sort="true"
          :items="aRawMaterial"
          :hide-default-footer="true"
          no-data-text="No hay información disponible para mostrar."
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-all-text': 'Todos',
          }"
          class="global-table"
          height="300px"
          max-height="300px"
          min-height="300px"
        >
          <template v-slot:[`item.Col1`]="{ item }">
            <v-btn
              @click="selectedRawMaterial(item)"
              icon
              :class="item.bSelected ? 'check-global' : 'uncheck-global'"
            >
              <v-icon v-if="item.bSelected" size="15">mdi-check</v-icon>
            </v-btn>
            <span>{{ item.Col1 }}</span>
          </template>
          <template v-slot:[`item.Col3`]="{ item }">
            <div class="content-color-id-raw-material">
              <div
                :style="{
                  'background-color': '#' + item.ColorHex + '!important',
                }"
                class="color-raw-material"
              ></div>
              <span>{{ item.Col3 }}</span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-global mt-2 mb-6"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              :disabled="bAddRawMaterial"
              :loading="bLoading"
              class="global-btn-primary"
              @click="addRawMaterial()"
            >
              Aceptar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: 0,
      checkbox: false,
      returnToView: false,
      bAdminGlobal: false,
      bLoading: false,
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "Col1",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Imagen",
          sortable: false,
          value: "Col2",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Clave",
          sortable: false,
          value: "Col3",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
      ],
      aRawMaterial: [
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
        {
          bSelected: false,
          Col1: "ACERO",
          Col2: "",
          ColorHex: "F078EC",
          Col3: "ACERO INOX 304",
        },
      ],
      bAddRawMaterial: true,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.close();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 70;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    addRawMaterial() {
      this.bLoading = true;

      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminGlobal) {
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },

    close() {
      this.checkbox = false;
      this.bLoading = false;
    },
    selectedRawMaterial(item) {
      item.bSelected = !item.bSelected;
      this.validateAddRawMaterial();
    },
    validateAddRawMaterial() {
      let lengthRawMAterials = this.aRawMaterial.filter(
        (item) => item.bSelected === true
      ).length;
      if (lengthRawMAterials >= 1) {
        this.bAddRawMaterial = false;
      } else {
        this.bAddRawMaterial = true;
      }
    },
  },
  computed: {
    dialogAddRawMaterialToPurchaseOrder() {
      return this.$store.state.dialogAddRawMaterialToPurchaseOrder;
    },
    //PERMISSIONS ADD RAW MATERIAL TO PURCHASE ORDER
  },
  watch: {
    dialogAddRawMaterialToPurchaseOrder() {
      if (this.dialogAddRawMaterialToPurchaseOrder) {
        this.returnToView = this.$store.state.returnToView;
      }
    },
  },
};
</script>
<style scoped>
.content-title-add-raw-material-purchase-order {
  width: 100%;
}
.content-description-add-raw-material-purchase-order {
  width: 100%;
}
.title-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}
.description-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: center;
}

.content-question-add-raw-material-purchase-order {
  width: 100%;
  justify-self: center;
  display: flex;
  justify-content: center;
  padding: 10px 45px 10px 45px;
}

.question-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}
.content-btn-primary {
  width: 150px;
}
.content-btn-second {
  width: 150px;
  margin-right: 15px;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.table-raw-material {
  height: 300px;
  overflow: auto;
}
/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>