var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":_vm.screenWidth + '%',"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.dialogAddRawMaterialToPurchaseOrder),callback:function ($$v) {_vm.dialogAddRawMaterialToPurchaseOrder=$$v},expression:"dialogAddRawMaterialToPurchaseOrder"}},[_c('v-card',{staticClass:"global-container-dialog"},[_c('v-btn',{staticClass:"global-btn-close",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1),_c('v-card-title',[_c('div',{staticClass:"content-title-add-raw-material-purchase-order"},[_c('p',{staticClass:"title-add-raw-material-purchase-order"},[_vm._v(" Añadir materia prima "),_c('br'),_vm._v(" a la orden de compra ")])])]),_c('v-card-text',[_c('v-data-table',{staticClass:"global-table",attrs:{"headers":_vm.headers,"disable-sort":true,"items":_vm.aRawMaterial,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        },"height":"300px","max-height":"300px","min-height":"300px"},scopedSlots:_vm._u([{key:"item.Col1",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{class:item.bSelected ? 'check-global' : 'uncheck-global',attrs:{"icon":""},on:{"click":function($event){return _vm.selectedRawMaterial(item)}}},[(item.bSelected)?_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-check")]):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.Col1))])]}},{key:"item.Col3",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"content-color-id-raw-material"},[_c('div',{staticClass:"color-raw-material",style:({
                'background-color': '#' + item.ColorHex + '!important',
              })}),_c('span',[_vm._v(_vm._s(item.Col3))])])]}}],null,true)})],1),_c('v-card-text',[_c('v-divider',{staticClass:"divider-global mt-2 mb-6"}),_c('div',{staticClass:"content-btns-accions"},[_c('div',{staticClass:"content-btn-second"},[_c('v-btn',{staticClass:"global-btn-neutral",on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1),_c('v-spacer'),_c('div',{staticClass:"content-btn-primary"},[_c('v-btn',{staticClass:"global-btn-primary",attrs:{"disabled":_vm.bAddRawMaterial,"loading":_vm.bLoading},on:{"click":function($event){return _vm.addRawMaterial()}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }